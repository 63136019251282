export const EN = {
  html: `<div>
    <p class="p1"><b>KUU Creator</b></p>
    <p class="p2"><b>Privacy Policy</b></p>
    <p class="p3"><b></b><br></p>
    <p class="p4">Welcome to KUU Creator Privacy Policy. The purpose of this privacy policy is to help you understand what information we and our third-party partners collect. This document contains the rules upon which such information is collected and used.</p>
    <p class="p5"><br></p>
    <p class="p6"><b>[Version: 1]</b></p>
    <p class="p6"><b>[14 Oct 2021]</b></p>
    <p class="p6">This Privacy Policy explains how Kunana Company Limited (“<b>Company</b>”) the owner of KUU Creator, collects, uses, and discloses your Personal Data from, and about, users of the website, applications, emails, and social media platforms of the Company (“<b>Platform</b>”), as well as the use of the Company’s services to provide online creative spaces via the Platform, including any interactions that you may have with our Platform and services (hereinafter called “<b>Services</b>”).</p>
    <p class="p6">We advise you to read this Privacy Policy in its entirety. By using the Company’s Platform, you agree to this Privacy Policy.</p>
    <p class="p5"><br></p>
    <p class="p6"><b>1. Definitions</b></p>
    <p class="p6"><b>“Company Content”</b> means: (i) the Content which is provided to the User by the Company via the Company’s Platform; and (ii) the User Content which is available in the Content Stock.</p>
    <p class="p6"><b>“Content Stock”</b> means the stock which contains Contents uploaded by the Company (upon the consent of the relevant users), or created/provided by the Company, whereby such Contents can be accessed and/or used by users of the Platform.</p>
    <p class="p6"><b>“Content”</b> means cutouts, layouts, artworks, icons, vectors, elements, designed texts, templates, photographs, graphics, footage, animation, or any other types of contents available on our Platform.</p>
    <p class="p6"><b>“Data Subject”</b> means a natural person who can be identified by the Personal Data, or a set or combination of Personal Data, whether directly or indirectly.</p>
    <p class="p6"><b>“Persona Data”</b> means any data pertaining to a natural person which enables the identification of such a person, whether directly or indirectly, but excluding the data of <br>
    a deceased person specifically.</p>
    <p class="p6"><b>“Process”</b> or <b>“Processing”</b> means any operation, or a set of operations, which is/are performed on Personal Data, including, without limitation to, the collection, use, disclosure, transfer, dissemination, recording, copying, organization, analysis, retention, correction, disposal, alteration, modification, retrieval, deletion, destruction, de-identification, or other forms of processing.</p>
    <p class="p6"><b>“Sensitive Personal Data”</b> means Personal Data which is subject to enhanced requirements as prescribed by Section 26 of the PDPA, which includes Personal Data pertaining to racial, ethnic, origin, political opinions, cult, religious or philosophical beliefs, sexual behavior, criminal records, health data, disability, labor union information, genetic data, biometric data, or any other data of a similar nature to be prescribed by the Personal Data Protection Commission (PDPC).</p>
    <p class="p6"><b>“User Content” </b>means the Content which is created, developed, and/or uploaded by the users through the Company’s Platform.<span class="Apple-converted-space"> </span></p>
    <p class="p5"><br></p>
    <p class="p6"><b>2. What types of Personal Data does the Company collect?</b></p>
    <p class="p6">To provide our Services, the Company will collect, use, and disclose your Personal Data, which is information that can be used to identify you as an individual, whether directly or indirectly, as follows:</p>
    <p class="p6"><b>2.1</b> <span class="Apple-tab-span">	</span><b>Personal Data that you provide to the Company</b> – The Company collects Personal Data that you: (i) provide in the registration process and use to create your account; and (ii) voluntarily provide to us when you use the Services. This would include, without limitation, your email addresses, telephone number(s), and other contact details, as well as feedback on the Company’s Services, and business information, such as your company name, your role, and industry.</p>
    <p class="p6">In the case where you provide the Personal Data of other persons to the Company’s Platform, you agree and guarantee that the Data Subject related to such Personal Data has already been informed about the use of their Personal Data and the information provided in this Privacy Policy. You also agree that, if required to do, you will fully assist the Company to obtain valid consent from the Data Subject in relation to the processing of such Personal Data.</p>
    <p class="p6"><b>2.2<span class="Apple-tab-span">	</span>Personal Data that we collect about your use of the Company’s Services</b> – The Company collects Personal Data about your use of the Services, and about the device that you use to access the Services, including:<span class="Apple-converted-space"> </span></p>
    <p class="p7">1.<span class="Apple-tab-span">	</span>Your behavior, interest, and online preferences;</p>
    <p class="p7">2. <span class="Apple-tab-span">	</span>Content that you create or upload to the Platform;</p>
    <p class="p7">3. <span class="Apple-tab-span">	</span>Content that you access, download, or import from the Platform;</p>
    <p class="p7">4. <span class="Apple-tab-span">	</span>The pages that you request and visit;<span class="Apple-converted-space"> </span></p>
    <p class="p7">5. <span class="Apple-tab-span">	</span>The posts/Content that you submit;</p>
    <p class="p7">6. <span class="Apple-tab-span">	</span>Information on your interactions with other users;</p>
    <p class="p7">7. <span class="Apple-tab-span">	</span>Information on your interactions with The Company services;</p>
    <p class="p7">8. <span class="Apple-tab-span">	</span>Information obtained in the course of maintaining or supporting the Services;</p>
    <p class="p8">9.<span class="Apple-tab-span">	</span>Information about your internet use, such as your IP address, the URLs of sites from which you arrive or leave in respect to the Services, your type of browser, your operating system, your internet service provider and, if you access the Services via your mobile device, and the Company may also collect information about your network service provider and the type of mobile device.</p>
    <p class="p6">The Company (and our service providers – if any) use different technology to collect your Personal Data, including cookies and web beacons. Cookies are small data files which are stored on your hard drive, or in the device memory, which help us to improve the Company’s Services and your experience, in order to see which areas and features of our Services are popular, and to count the number of visits. Web beacons are electronic images that may be used in our Services or emails, which help to deliver cookies, count the number of visits, and which enable us to understand usage and campaign effectiveness.<span class="Apple-converted-space"> </span></p>
    <p class="p6"><b>2.3<span class="Apple-tab-span">	</span>Advertising and Analytics Services provided by Others </b>– The Company may conduct analytics in relation to the Platform and Services, whether by the Company itself or via a third-party service provider of the Company. In conducting the analytics, the Company (and the third-party service provider – if any) may use cookies, web beacons, device identifiers, and other technology to collect information about your use of the Service and other websites and applications, including your IP address, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and conversion information.</p>
    <p class="p6">Please note that the Company does not intend to process or discourage you from provide any Personal Data which may be regarded as ‘Sensitive Personal Data’ to the Company’s Platform.</p>
    <p class="p5"><br></p>
    <p class="p6"><b>3. Why does the Company Collect and Process Your Personal Data?</b></p>
    <p class="p6">The Company collects and processes your Personal Data for various purposes, as set out below:</p>
    <p class="p8">1. <span class="Apple-tab-span">	</span>To provide, maintain, and improve the Services, including the provision of the Content Stock for users;</p>
    <p class="p8">2. <span class="Apple-tab-span">	</span>To send you technical notices, updates, security alerts, and support and administrative messages (such as changes to our terms, conditions, and policies), and to respond to your comments, questions, and customer service requests;</p>
    <p class="p8">3. <span class="Apple-tab-span">	</span>To receive and respond to any submissions that you may provide about the Services, such as submissions relating to feedback;</p>
    <p class="p8">4. <span class="Apple-tab-span">	</span>To permit you to participate in voluntary polls and surveys, which may be conducted via the Company’s Platform;</p>
    <p class="p8">5. <span class="Apple-tab-span">	</span>To communicate with you about products, services, marketing campaigns, and events offered by the Company and other business partners (if any), which the Company thinks will be of interest to you;</p>
    <p class="p8">6. <span class="Apple-tab-span">	</span>To communicate with you about updates, news, and any information relating to the products and services of the Company;</p>
    <p class="p8">7. <span class="Apple-tab-span">	</span>To monitor and analyze trends, usage, and activities in connection with our Services;</p>
    <p class="p8">8. <span class="Apple-tab-span">	</span>To research and develop new products and services, and to improve our current products and services;</p>
    <p class="p8">9. <span class="Apple-tab-span">	</span>To analyze and track data, determine the popularity of certain content, and deliver advertising to allow us to understand your interests and online activity;<span class="Apple-converted-space"> </span></p>
    <p class="p8">10.<span class="Apple-tab-span">	</span>To detect, investigate, and prevent fraudulent transactions and other illegal activities, and to protect the rights and property of the Company and others; and</p>
    <p class="p8">11.<span class="Apple-tab-span">	</span>To carry out any other purpose as described to you at the time when the Personal Data is collected.</p>
    <p class="p6">In conclusion, the Company mainly collects, uses, and discloses your Personal Data for the purpose of the provision of the Company’s Services, and to monitor and develop the Services, and your Personal Data is necessary for such purposes. For certain purposes, such as advertising and content which is targeted towards your interests in regard to our Services, and offering products which may be of interest to you, the Company will use your Personal Data once we have obtained your consent only, and you are allowed to withdraw your consent at any time.<span class="Apple-converted-space"> </span></p>
    <p class="p5"><br></p>
    <p class="p6"><b>4. To whom does the Company Disclose Your Personal Data?</b></p>
    <p class="p6">In general, the Company will not disclose your Personal Data to a third party, except it is necessary. In that case, we may disclose your Personal Data to:</p>
    <p class="p8">1. <span class="Apple-tab-span">	</span>Our service providers, who/which provide services such as cloud services, website hosting, data analysis, information technology and related infrastructure provision, payment gateway services, payment facilitating services, customer services, processing your transactions, e-mail delivery, auditing, and other services;</p>
    <p class="p8">2. <span class="Apple-tab-span">	</span>Public and government authorities, in order to cooperate with them in regard to law enforcement, and to respond to their requests, or to provide them with information as required. For other legal reasons, such as to monitor compliance with and to enforce our terms and conditions, as well as to protect our rights, privacy, safety, or property, and/or that of our affiliates, you or others, and to protect against fraud, crime, illegal activity, anti-money laundering, or anti-terrorism, and for risk management purposes;</p>
    <p class="p8">3. <span class="Apple-tab-span">	</span>Contracting party/parties in connection with a sale or a business transaction, such as the acquiring entity or its advisors, in the event of any re-organization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings);</p>
    <p class="p8">4. <span class="Apple-tab-span">	</span>Our business partners, subsidiaries, affiliates, or other contracting parties for the purposes specified in this Privacy Policy and</p>
    <p class="p8">5. <span class="Apple-tab-span">	</span>Other third parties that the Company may inform you about at the time of collecting your Personal Data (if any).</p>
    <p class="p6">The persons/entities which your Personal Data may be disclosed to/shared with are located both in Thailand and outside Thailand. Regardless of where we process your Personal Data, we will still treat it in accordance with this Privacy Policy and the relevant laws, and <br>
    in particular, the Personal Data protection law.</p>
    <p class="p9"><br></p>
    <p class="p6"><b>5. Children</b></p>
    <p class="p6">Our Services are not designed for children, and we do not knowingly collect Personal Data from children who are under the age of 20. If you have reason to believe that a child has provided Personal Data to us, please contact us, and we will take the necessary action.</p>
    <p class="p9"><br></p>
    <p class="p6"><b>6. Links to Other Websites</b></p>
    <p class="p6">Our Services may contain links to other websites or applications. Please note that the Company is not responsible for the privacy or information security practices of other websites. You should carefully review the applicable privacy and information security policies and notices for any other websites that you access via our Platform. This Privacy Policy applies solely to your Personal Data which is collected by the Services.</p>
    <p class="p9"><br></p>
    <p class="p6"><b>7. Security</b></p>
    <p class="p6">The security of your Personal Data is a priority of the Company. The Company aims to protect this information by implementing and maintaining reasonable physical, electronic, and procedural security measures and safeguards, which are designed to protect Personal Data which is under the Company’s responsibility. Unfortunately, no data transmission over the Internet, or wireless networks, or data storage systems, can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account that you might have with us has been compromised), please immediately contact us in accordance with details provided in the Contact Us section below.<span class="Apple-converted-space"> </span></p>
    <p class="p9"><br></p>
    <p class="p6"><b>8. For how long does the Company retain your Personal Data?</b></p>
    <p class="p6">The Company will retain your Personal Data for as long as is required, in order to fulfil our Services to you, and for as we have a valid purpose to do so or after the cessation of our Services, or for a statutory limitation period as prescribed by applicable law.</p>
    <p class="p6">Where the Company processes your Personal Data in connection with a legal obligation, your Personal Data will be retained for the duration of the prescribed legal retention period.<span class="Apple-converted-space"> </span></p>
    <p class="p6">When the retention period is met, your Personal Data will be deleted/destroyed accordingly, unless otherwise permitted or prescribed by the applicable law.</p>
    <p class="p5"><br></p>
    <p class="p6"><b>9. What are your rights in relation to your Personal Data?</b></p>
    <p class="p6">You are entitled to:<span class="Apple-converted-space"> </span></p>
    <p class="p8">1. <span class="Apple-tab-span">	</span>Request to have access to, and obtain a copy of, your Personal Data, and to request the disclosure of the source of the Personal Data, in the event that your Personal Data was collected without your consent;<span class="Apple-converted-space"> </span></p>
    <p class="p8">2. <span class="Apple-tab-span">	</span>Receive your Personal Data in a commonly used and machine-readable format, and to have your Personal Data in said format transmitted to another Data Controller, where the Company retains the Personal Data in such format;<span class="Apple-converted-space"> </span></p>
    <p class="p7">3. <span class="Apple-tab-span">	</span>Request that your Personal Data be deleted, destroyed, or de-identified;<span class="Apple-converted-space"> </span></p>
    <p class="p8">4. <span class="Apple-tab-span">	</span>Object to the collection, use, and disclosure of your Personal Data, and especially where the collection, use, or disclosure is for a direct marketing purpose;<span class="Apple-converted-space"> </span></p>
    <p class="p7">5. <span class="Apple-tab-span">	</span>Request that the processing of your Personal Data be suspended;<span class="Apple-converted-space"> </span></p>
    <p class="p7">6. <span class="Apple-tab-span">	</span>Request that your Personal Data be corrected, updated or completed;<span class="Apple-converted-space"> </span></p>
    <p class="p8">7. <span class="Apple-tab-span">	</span>Withdraw your consent at any time, provided there is no other legal ground for the Company to continue to process your Personal Data; and<span class="Apple-converted-space"> </span></p>
    <p class="p8">8. <span class="Apple-tab-span">	</span>Lodge a complaint to the Office of the Personal Data Protection Commission (“Office of PDPC”) and its expert committee.<span class="Apple-converted-space"> </span></p>
    <p class="p6">Please note that the exercise of your rights is based on the conditions and limitations stated by the law, and we may have the right to refuse to comply with your request, as permitted by law.</p>
    <p class="p5"><br></p>
    <p class="p6"><b>10. Cookies ( for website )</b></p>
    <p class="p6"><b>10.1 What are cookies?</b></p>
    <p class="p6">Cookies are files providing the storage of information or access to information which is already stored in the telecommunication terminal equipment when, or after, you visit the Company’s website. The cookies may be used, among others, to record the users’ preferences, protect websites, or to conduct marketing campaigns. Unless you give consent to the installation of cookies, certain functionalities relating to the Company’s Services will be disabled.</p>
    <p class="p6"><b>10.2 What type of cookies do we use?</b></p>
    <p class="p7">1. <span class="Apple-tab-span">	</span><b>Persistent cookies</b> – They are recorded on your device even after you leave the website. They allow your preferences to be stored and remembered, such as the username (login) which is used to login to the transaction service system.<span class="Apple-converted-space"> </span></p>
    <p class="p7">2. <span class="Apple-tab-span">	</span><b>Session cookies </b>– They are necessary to support information exchange between the services of the Company and the web browsers and, consequently, <br>
    to enable the accurate display of the website and its functionalities. Their purpose is to enable identification of a given session, i.e. the dialogue between the browser and the server, and users communicating with the server at the same time.</p>
    <p class="p7">3. <span class="Apple-tab-span">	</span><b>Other cookies </b>– The Company may use cookies to gather information about the accounts of the Company’s website visitors, their behavior, preferences, and interests in respect to individual products. These cookies may enable the display of ads and marketing offers, and analysis of information relating to your interests in the displayed materials.<b><span class="Apple-converted-space"> </span></b></p>
    <p class="p6"><b>10.3 How can you refuse/withdraw your consent for the installation of cookies?</b></p>
    <p class="p6">Most web browsers are set up to accept cookies by default, to ensure convenient use of websites and the correct display of website content. The management of cookies may be changed by adjusting the web browsers settings at any time. Refusal to the use of necessary cookies or withdrawal of your consent for the use of necessary cookies may affect certain functions on the Company’s website, which may cause certain features to be fully or partly disabled.<span class="Apple-converted-space"> </span></p>
    <p class="p6">If you would like to change the browser setting for cookies, you can inform us at hello@kuupixmaker.ai, or change your privacy setting in your privacy setting banner.</p>
    <p class="p6">In the case where cookies are used for purposes which require consent, i.e. marketing purposes, as described above, the Company will process your Personal Data only upon obtaining your consent. In addition, you are also allowed to withdraw your consent at any time in your privacy setting banner.</p>
    <p class="p5"><span class="Apple-converted-space"> </span></p>
    <p class="p6"><b>11. Updating Your Personal Information</b></p>
    <p class="p6">Keeping your account information accurate and up to date is very important. If your account information is incomplete, inaccurate, or not current, please use the Contact Us option on our Services, or update the Personal Data on Services yourself (if applicable), or call or write to us at the telephone numbers, or at the appropriate address, so that changes can be carried out to the Personal Data which is listed in your account statements, records, online, or in other account materials.<span class="Apple-converted-space"> </span></p>
    <p class="p5"><span class="Apple-converted-space"> </span></p>
    <p class="p6"><b>12. Changes to This Privacy Policy</b></p>
    <p class="p6">From time to time, the Company may make changes to this Privacy Policy. Changes may be made for any number of reasons, including to reflect industry initiatives, changes in the law, and changes to the scope of Services, among other reasons. In the event that the amendment, change, or update will affect the purposes for which your Personal Data has originally been collected, the Company will notify you about such changes, and obtain your consent <br>
    (if required/necessary). Any changes will become effective when we post the revised Privacy Policy on our website. Please take a look at the Last Updated legend at the end of this Privacy Policy, to see when this Privacy Policy was last revised.</p>
    <p class="p5"><br></p>
    <p class="p6"><b>13. Contact Us</b></p>
    <p class="p6">If you have any inquiries in relation to your Personal Data, or you would like to exercise any of your Data Subject Rights, you may contact us at:</p>
    <p class="p6">Kunana Company Limited</p>
    <p class="p6">Address: Rasa Tower 1 555 Phahonyothin Chatuchak Bangkok 10900 Thailand</p>
    <p class="p6">E-mail: hello@kuupixmaker.ai</p>
    <p class="p5"><br></p>
    <p class="p5"><br></p>
    <p class="p6">This Privacy Policy shall take effect from 14 Oct 2021 onward.<span class="Apple-converted-space"> </span></p>
    <p class="p6">_________________________________________________________________</p>
    <p class="p10">Personal Data collected for the following purposes and using the following services:</p>
    <p class="p11"><b>Analytics</b></p>
    <p class="p12"><b>Google Analytics for Firebase<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: Application opens; Application updates; device information; first launches; geography/region; In-app purchases; number of sessions; number of Users; operating systems; session duration; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data</p>
    <p class="p12"><b>Facebook Analytics for Apps<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</p>
    <p class="p11"><b>Device permissions for Personal Data access</b></p>
    <p class="p12"><b>Device permissions for Personal Data access<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: Camera permission; Photo Library permission; Write-only Photo Library permission</p>
    <p class="p13"><b>Handling payments</b></p>
    <p class="p12"><b>Payments processed via the Apple App Store</b></p>
    <p class="p12"><b><span class="Apple-tab-span">	</span></b>Personal Data: payment data</p>
    <p class="p12"><b>RevenueCat<span class="Apple-converted-space"> </span></b></p>
    <p class="p12"><b>Payments processed via the Google Play Store<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: purchase history</p>
    <p class="p13"><b>Hosting and backend infrastructure</b></p>
    <p class="p12"><b>Google Cloud Storage<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</p>
    <p class="p13"><b>Infrastructure monitoring</b></p>
    <p class="p12"><b>Sentry<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: various types of Data as specified in the privacy policy of the service</p>
    <p class="p13"><b>Managing contacts and sending messages</b></p>
    <p class="p12"><b>Firebase Cloud Messaging<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: various types of Data as specified in the privacy policy of the service</p>
    <p class="p12"><b>Sendgrid</b></p>
    <p class="p14"><b><span class="Apple-tab-span">	</span></b>Personal Data: various types of Data as specified in the privacy policy of the service<span class="Apple-converted-space"> </span></p>
    <p class="p13"><b>Platform services and hosting</b></p>
    <p class="p12"><b>Apple App Store and Google Play Store<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: Usage Data</p>
    <p class="p13"><b>Registration and authentication</b></p>
    <p class="p12"><b>Firebase Authentication<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: email address</p>
    <p class="p12"><b>Sign in with Apple<br>
    <span class="Apple-tab-span">	</span></b>Personal Data: email address; first name; last name</p>
    </div>`,
};
